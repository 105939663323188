








import { Component, Vue } from 'vue-property-decorator'

type Transition = 'right' | 'left' | 'none'

@Component
export default class ValuationStep extends Vue {
  active = false
  transition: Transition = 'right'
  stepValidationHandler: Function = () => null
  stepSubmissionHandler: Function = () => null

  show (transition: Transition) {
    this.transition = transition
    this.active = true
  }

  hide (transition: Transition) {
    this.transition = transition
    this.active = false
  }

  submit () {
    return this.stepSubmissionHandler()
  }

  validate () {
    return this.stepValidationHandler()
  }

  validationHandler (handler: Function) {
    this.stepValidationHandler = handler
  }

  submissionHandler (handler: Function) {
    this.stepSubmissionHandler = handler
  }
}
