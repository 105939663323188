type ComponentLookup = {
  [key: string]: {
    [key: string]: string;
  };
};

export const componentsLookupTable: ComponentLookup = {
  'd9731c32-c12c-4a9f-b73e-0bb1b6868531': {
    '0d935727-112b-4c3b-942b-ae0e95264126':
        'CommercialFreestandingMedicalProperty',
    '899806dd-5680-435e-beb8-f90c7503635f':
        'CommercialFreestandingOfficeProperty',
    '207e0dba-cd9a-4a42-90e5-9047cbe8a159':
        'CommercialMultitenantMedicalPremises',
    'dab82a5f-6aac-4a11-8cc1-d95b0eaaf66e':
        'CommercialMultitenantOfficePremises',
    '4d03c4ae-a819-438e-8132-5c40963c2641': 'CommercialStrataMedicalSuite',
    '9c74e801-1cee-4608-8f1d-6ac37d414492': 'CommercialStrataOfficeSuite',
    '1c666298-f2d6-4f19-8b38-a47fc3987212': 'CommonVacantLand'
  },
  '2f478d9a-2ec7-4cd1-a9f3-22ee57d6e7e9': {
    '7a518e19-e8db-4e19-98c6-e48ed5fdb1bb': 'IndustrialFactory',
    'b5a1fde3-7b21-4f04-9400-c0b0d314a0e6':
        'IndustrialServiceIndustryLightIndustrial',
    'c01b0c60-19aa-4373-b84f-a11cb99f9676': 'CommonVacantLand',
    '3db668a1-fa6a-49ca-b0af-24b5aec68ca7': 'IndustrialWarehouse'
  },
  '82957b62-379e-468e-8f60-adcf33cf8f2e': {
    '3964dc78-5a6f-4395-aa83-3548351db5ba': 'ResidentialApartment',
    '9f2f8397-261d-45e0-b813-16b2518c3760': 'ResidentialBlockOfApartments',
    '5b14fff0-8a46-46b6-b376-53739f32afa0': 'ResidentialDetachedResidence',
    'cc80ffa3-b40d-4816-b7b8-b2fa86e1598f': 'ResidentialDuplex',
    'df5dead7-179b-4820-be1c-eca6733cdfc1': 'ResidentialTerraceHouse',
    'd3658cf8-3862-44d1-8648-b777e5d9efd9': 'ResidentialTownhouse',
    '9ca89f78-85db-4c39-854b-31090a98fed5': 'ResidentialTownhouseComplex',
    '9df25ed4-de03-4257-82ae-4e89e712053b': 'CommonVacantLand'
  },
  '8500289e-4570-4d7b-a33c-6dede9c1b419': {
    '26dc47da-b5ac-490d-89e5-d7a252373159':
        'RetailFreestandingRetailProperty',
    '3ad9c502-3e6e-4dac-8b4f-f8f6b7fe5832':
        'RetailShowroomBulkyGoodsRetailProperty',
    '3d445670-01a4-4cfe-a984-5f89f0ef9510': 'RetailStrataRetailSpace',
    'c49656af-cd78-4906-a078-c1b5cf505996': 'RetailStripShopRetailProperty'
  },
  '65b23448-f76c-4e8d-b984-892bed5e84d1': {
    '4b174a58-ccaa-4a96-b774-b092e07d8bf7': 'RuralCroppingFarm',
    'ebdd433c-68ce-4d42-8bde-75af231952ea': 'RuralGrazingFarm',
    '3e57a63a-f81b-40a1-a77f-26a1b47308e6': 'RuralHobbyFarm',
    '9bd0510e-aeb2-4ac9-aa0d-f9ca123faffa': 'CommonVacantLand'
  }
}

type ComponentImport = {
  [key: string]: () => unknown;
};

export const componentsImports = () => {
  const components: ComponentImport = {}

  Object.values(componentsLookupTable)
    .flatMap(Object.values)
    .forEach((c: string) => {
      components[c] = () => import(`../PropertyForms/${c}.vue`)
    })

  return components
}

// This is a helper function to generate the components lookup object
// const typesPromise = Services.API.Enumerations.GetEnumValuesForType(EnumValueType.PropertyType).then((vals) => {
//   this.propertyTypes = vals.map((v) => ({ label: v.Label, value: v.ValueId }))
// })
// const classificationsPromise = Services.API.Enumerations.GetEnumValuesForType(EnumValueType.PropertySubType).then((vals) => {
//   this.allPropertyClassifications = vals.map((v) => ({ label: v.Label, value: v.ValueId, typeId: v.ParentId }))
// })
// Promise.all([typesPromise, classificationsPromise]).then(() => {
//   const lookups: any = {}
//   this.propertyTypes.forEach((pt) => {
//     const classifications = this.allPropertyClassifications.filter((v) => v.typeId === pt.value)
//     const key = pt.value
//     lookups[key] = {}
//     classifications.forEach((c) => {
//       lookups[key][c.value] = `${pt.label}${c.label.replace(/[\W_]+/g, '')}`
//     })
//   })
//   console.log(JSON.stringify(lookups))
// })
